<template>
	<div class="footerIns">
	<div class="center">
		<h3>
			Follow Us:&nbsp;&nbsp;
			<v-btn icon href="https://www.instagram.com/tblsystems_inc/" target="_blank">
				<v-icon>mdi-instagram</v-icon>&nbsp;&nbsp;
			</v-btn>	
			<v-btn icon href="https://www.linkedin.com/company/tbl-systems-inc/" target="_blank">
				<v-icon>mdi-linkedin</v-icon>
			</v-btn>	
		</h3>
	</div>
	<v-container center>
		<v-row>
			<v-col
				v-for="item in menu3"
				:key="item.id"
			>
				<font class="header">
					{{ item.name }}
				</font>
				<br>
				<div class=footerCont>
				<v-container 
				>
					<v-row
						v-for="child in item.children"
						:key="child.id"
						no-gutters
					>
						<v-col 
							cols=12;
							class="text-left"
						>
							<a
								@mouseover="linkMO($event)"
								@mouseleave="linkML($event)"
								:href="child.link"
							>
								{{ child.name }}
							</a>
						</v-col>
					</v-row>
				</v-container>
				</div>
			</v-col>
			<v-col
				class="hidden-sm-and-down contactBox"
			>
				<div class="footerCont text-left">
					<div class="center">
						<router-link to="/">
							<v-img
								class="mx-auto"
								alt="TBL Systems Logo"
								contain
								src="../assets/logo_tblsys_white_small.png"
								transition="scale-transition"
								width="125"
							/>
						</router-link>
					</div>
					<br>
					<div v-if="address">
						<h4>Corporate Headqurters</h4>
						<span v-html=address> </span>	
					</div>
					<div v-else>
					</div>
					<v-container 
										>
						<v-row
							v-for="item in menu4"
							:key="item.id"
							no-gutters
						>
							<v-col 
								cols=12;
								class="text-left"
							>
								<a
									@mouseover="linkMO($event)"
									@mouseleave="linkML($event)"
									:href="item.link"
								>
									{{ item.name }}
								</a>
							</v-col>
						</v-row>
					</v-container>
				</div>
			</v-col>
		</v-row>
	</v-container>
<div class="center contactBox hidden-md-and-up">
	<br>
	<div class="center">
	<router-link to="/">
		<v-img
			class="mx-auto"
			alt="TBL Systems Logo"
			contain
			src="../assets/logo_tblsys_white_small.png"
			transition="scale-transition"
			width="125"
		/>
	</router-link>
	</div>
	<br>
	<div v-if="address">
		<h4>Corporate Headqurters</h4>
		<span v-html=address> </span>	
	</div>
	<div v-else>
	</div>
	<v-container 
	>
		<v-row>
			<v-col 
				v-for="item in menu4"
				:key="item.id"
				cols="6"
			>
				<a
					@mouseover="linkMO($event)"
					@mouseleave="linkML($event)"
					:href="item.link"
				>
					{{ item.name }}
				</a>&nbsp;&nbsp;
			</v-col>
		</v-row>
	</v-container>
</div>
<br>
<div class="center">
	<h4>iOS App Available at </h4>
	<v-flex class="pa-10 pb-8 text-center">
	<a href="https://apps.apple.com/us/app/tbl-universal-reporting/id1163425064" target="_blank">
		<v-img
			class="mx-auto"
			alt="Download on App Store"
			contain
			src="../assets/app_store.png"
			transition="scale-transition"
			width="160"
		/>
	</a>
	<br>
	<a href="https://www.firstnet.com" target="_blank">
		<v-img
			class="mx-auto"
			alt="FirstNet"
			contain
			src="../assets/firstnet-logo-desktop.png"
			transition="scale-transition"
			width="160"
		/>
	</a>
	</v-flex>
	<br>
	<h4>Find TBL-Compatable Products at </h4>
	<v-flex class="pa-10 pb-8 text-center">
	<a href="https://www.frontlinesupplies.com" target="_blank">
		<v-img
			class="mx-auto"
			alt="TBL Systems Logo"
			contain
			src="../assets/frontline_supplies_white.png"
			transition="scale-transition"
			width="160"
		/>
	</a>
	</v-flex>
</div>
	
	
<div class="center">
	<h3><a href="tel:18008446258">Call Sales:  1-855-844-6258 </a></h3>
	<br>
	Copyright &copy; 2012 - {{ new Date().getFullYear() }}, TBL Systems, Inc.	All Rights Reserved.
	<br>
	<br>
</div>
</template>

<style>
.nowrap {
white-space: nowrap;
}

.contactBox {
background-color: #353535; 
}

.footerCont {
display: inline-block;
margin: 0 auto;
}
.left {
float: left;
}
.header {
font-weight: bold;
display: inline-block;
}
.center {
text-align: center;
justify-content: center;
}

.footerIns {
max-width: 1800px;
width: 1800px;
height: 100%;
margin: 0 auto;
}
</style>

<script>
export default {
name: "Footer",
data: function() {
	return {
		address: null,
		menu3: {numCols: 3},
		menu4: {}
	}	
},

methods: {
	linkMO(e) {
	},
	linkML(e) {
	},
	sortFunction(a,b) {
		var nameA = a.name.toUpperCase(); // ignore upper and lowercase
		var nameB = b.name.toUpperCase(); // ignore upper and lowercase

		if (nameA < nameB) {
			return -1;
		}

		if (nameA > nameB) {
			return 1;
		}

		// names must be equal
		return 0;
	},
	parseMenu(response, m) {
		var menu = response.data.menuList;
		var newMenu = {}
		var rootID=0;

		// find the root ID
		for(const i in menu) {
			menu[i].children = []
			menu[i].handle = menu[i].link.replace("/","")
			newMenu[menu[i]["id"]] = menu[i];
			if(menu[i].parent==-1) {
				rootID = menu[i].id
			}
		}

		// build tree
		for(const id in newMenu) {
			var pid = newMenu[id].parent
			if(pid!=-1) {
				newMenu[pid].children.push(newMenu[id])
			}
		}

		// set icon based on number of children.
		// also, remove url link if we have children
		for(const id in newMenu) {
			if(newMenu[id].children.length>0) {
				newMenu[id]["route"] = newMenu[id].link;
				newMenu[id].link = ""
			} else if(newMenu[id].snippet.length>0) {
				newMenu[id]["route"] = newMenu[id].link;
				newMenu[id].link = ""
			}
		}
		
		// Sort Menu by sort_order
		for(const i in newMenu[rootID].children) {
			try {
				newMenu[rootID].children[i].children = newMenu[rootID].children[i].children.sort(this.sortFunction);
			} catch(e) {
				console.log("Can't sort children of " + newMenu.children[i].name)
				continue;
			}
		}
		this[m] = newMenu[rootID].children.sort(this.sortFunction);

		this[m].numCols = 12/this[m].length;
	}
},

mounted() {
	this.$http.get("/svc/menu/4/").then(response => {
			this.parseMenu(response, "menu4");
		})
	this.$http.get("/svc/menu/3/").then(response => {
			this.parseMenu(response, "menu3");
		})
	this.$http.get("/svc/address/headquarters/").then(response => {
			try {
				this.address = response.data.address[0]["displayAddress"];
			} catch(e) {
				this.address = null;
			}
		})
	}
}
</script>
