import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import axios from 'axios';

Vue.use(VueRouter)
Vue.use(vuetify)

Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
	el: "#app",
	vuetify,
	router,
	render: h => h(App),
		data () {
		return {
			newMessages: 0,
			isMA: false,
			isInvestor: false,
			isReporter: false,
			isBlogger: false,
			isAgent: false,
			investor: {
				loggedIn: false,
				FirstName: ""
			}
		}

	},
	methods: {
		updateNewMessages() {

			// Check for Mail
			if(this.$root.investor.loggedIn==true) {
				var headers = {
					'Content-Type': 'application/json;charset=utf-8'
				};

				headers['USERNAME'] = this.$root.investor.username;
				headers['PASSWORD'] = this.$root.investor.password;

				this.$http.get("/svc/contact/new/", {headers}).then(response => {
					if(response.data.code==200) {
						this.newMessages = response.data.new;
					} else {
						console.log(response.data.description)
					}
				})
			}
		},

		hasRole(r) {
			for(var _r in this.$root.investor.roles) {
				if(r==this.$root.investor.roles[_r]) {
					return(true);
				}
			}

			return(false);
		}

	}

})
