<template>
	<v-container>
		<v-app-bar
			id="app-bar"
			app
		>
			<div class="d-flex align-center">
				<v-img
					alt="TBL Systems Logo"
					class="shrink mr-2 link"
					contain
					src="../assets/logo_only_tblsys_rwb.png"
					transition="scale-transition"
					width="40"
					@click="go('/')"
				/>

				<v-img
					alt="TBL Systems Name"
					class="shrink mt-1 hidden-sm-and-down link"
					contain
					min-width="200"
					src="../assets/logo_tblsys_words.png"
					width="100"
					@click="go('/')"
				/>
			</div>

			<v-spacer class=""></v-spacer>
			<v-menu
				v-if="$root.investor.loggedIn==true"
				:close-on-click="true"
				:offset-y="true"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-bind="attrs"
						v-on="on"
						outlined
					>
						<h3>Welcome {{ $root.investor.FirstName }}</h3>
						<v-icon
							v-if="$root.investor.dfAuth==false"
							class="pl-3"
						>
							mdi-pencil-lock
						</v-icon>
						<v-icon
							v-else
							class="pl-3"
						>
							mdi-pencil
						</v-icon>
					</v-btn>
				</template>

				<v-list>
					<v-list-item
						v-if="$root.investor.isMA==true || $root.investor.isReporter==true"
					>
						<v-list-item-title>
							<a	
								:href="'/reporter/'+$root.investor.uid+'/'"
							>
								My Press Releases	
							</a>
						</v-list-item-title>
					</v-list-item>

					<v-list-item
						v-if="$root.investor.isMA==true || $root.investor.isBlogger==true"
					>
						<v-list-item-title>
							<a
								:href="'/blogger/'+$root.investor.uid+'/'"
							>
								My Blogs	
							</a>
						</v-list-item-title>
					</v-list-item>

					<v-list-item
						v-if="$root.investor.isMA==true || $root.hasRole('campaigner')==true"
					>
						<v-list-item-title>
							<a
								:href="'/campaigns/'+$root.investor.uid+'/'"
							>
								My Campaigns 
							</a>
						</v-list-item-title>
					</v-list-item>

					<v-list-item
						v-if="$root.investor.isMA==true || $root.investor.isUpdater==true"
					>
						<v-list-item-title>
							<a
								:href="'/updater/'+$root.investor.uid+'/'"
							>
								My Updates 
							</a>
						</v-list-item-title>
					</v-list-item>
					<v-divider></v-divider>

					<v-list-item
						v-if="$root.investor.isMA==true || $root.investor.isInvestor==true"
					>
						<v-list-item-title>
							<router-link
								to="/investor/"
							>
								Investor Info	
							</router-link>
						</v-list-item-title>
					</v-list-item>

					<v-list-item
						v-if="$root.investor.isMA==true || $root.investor.isAgent==true"
					>
						<v-list-item-title>
							<router-link
								to="/messages"
							>
								Messages	
								<span
									class="red--text text--darken-4"
									v-if="$root.newMessages>0"
								>
										{{ $root.newMessages }}
								</span>
							</router-link>
						</v-list-item-title>
					</v-list-item>
					
					<v-list-item
						v-if="$root.investor.isMA==true || $root.hasRole('campaigner')==true"
					>
						<v-list-item-title>
							<router-link
								to="/prospects/"
							>
								Prospects
							</router-link>
						</v-list-item-title>
					</v-list-item>

					<v-divider></v-divider>
					<v-list-item
						v-if="$root.investor.isInvestor==true"
					>
						<v-list-item-title>
                                                        <a v-on:click.stop="investorLogout($event)">
                                                                Sign Out
                                                        </a>
						</v-list-item-title>
					</v-list-item>

				</v-list>
			</v-menu>
			<v-btn
				v-else
				to="/plans/5/"
				outlined
			>
				<span class="mr-2">Buy Now and Save</span>
				<v-icon>mdi-open-in-new</v-icon>
			</v-btn>

			<v-spacer class="hidden-md-and-up"></v-spacer>

			<div class="hidden-md-and-up">
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn 
						id="mobile=menu-button"
						icon
						v-bind="attrs"
						v-on="on"
						class="hiddden-md-and-up"
					>
						<v-icon aria-hidden="false">
							mdi-menu
						</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="item in menu"
						:key="item.icon"
						:to="item.link"
					>
						<v-list-item-title>{{ item.name }}</v-list-item-title>
					</v-list-item>
					<v-divider></v-divider>
					<v-list-item
						v-for="item in menu2"
						:key="item.icon"
						:to="item.link"
					>
						<v-list-item-title>{{ item.name }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			</div>
	
			<template v-slot:extension>
				<v-tabs
					centered
					optional
				>
					<v-tab
						class="hidden-sm-and-down"
						centered

						v-for="item in menu"
							:key="item.icon"
							:to="item.link"
							@mouseover="menuMO($event, item)"
							@mouseleave = "menuML($event, item)"
							@click = "menuC($event, item)"
																					>
						<v-icon> {{ item.icon }} </v-icon>
						{{ item.name }}
						<v-icon> {{ item.action.name }} </v-icon>
					</v-tab>
				</v-tabs>
				<v-tabs
					centered
					optional
				>
					<v-tab
						class="hidden-sm-and-down"
						centered
						v-for="item in menu2"
							:key="item.icon"
							:href="'#tab-' + item.handle"
							@mouseover="menuMO($event, item)"
							@mouseleave="menuML($event, item)"
							@click = "menuC($event, item)"
						>
						<v-icon> {{ item.icon }} </v-icon>
						{{ item.name }}
						<v-icon> 
							{{ item.action.name }} 
						</v-icon>
				</v-tabs>
			</template>
		</v-app-bar>
		<div 
			id="menuL2"
			@mouseover="L2MO($event)"
			@mouseleave="L2ML($event)"
		>
			<div 
				id="menuL2Ins"
			>
			<v-container>
				<v-row>
					<v-col
						v-for="item in menuL2.children"
						:key="item.id"
						:cols="numCols"
					>
					<b>	{{ item.name }}	</b>
					<hr class="dividerL2" >
			
					<v-container>	
					<v-row>
						<v-col
							v-for="ni in item.children"
							:key="ni.id"	
							:cols="4"
						>
							<div class="lblock"
							>
								<a class="hlink"
									@mouseover="linkMO($event)"
									@mouseleave="linkML($event)"
									:href="ni.route"
								>
									{{ ni.name }}
								</a>
								<div class="hdesc">
									{{ ni.snippet }}
								</div>
							</div>
						</v-col>
					</v-row>	
					</v-container>	
					</v-col>
				<v-row>
			</v-container>
			</div>
		</div>
		<div
			@mouseover="L2MO($event)"
			@mouseleave="L2ML($event)"
			id="menuRL2"
		>
			<div
				v-if="menuL2.snippet.length>0"
			>
				<v-container>
					<v-row>
						<v-col
							:cols="12"
						>
							<span v-html=menuL2.snippet> </span>
						</v-col>
					</v-row>
				</v-container>
			</div>
			<div 
				v-else-if="menuL2.children.length>0"
			>
				<v-container>	
					<v-row dense>
						<v-col
							v-for="item in menuL2.children"
							:key="item.id"	
							:cols="12"
						>
								<a 
									@mouseover="linkMO($event)"
									@mouseleave="linkML($event)"
									:href="item.link"
								>
									{{ item.name }}
								</a>
						</v-col>
						<v-col
							v-if="$root.investor.loggedIn==true"
						>
						<v-divider></v-divider><br>
							<a v-on:click.stop="investorLogout($event)">
								Sign Out
							</a>
						</v-col>
						<v-col v-else>
						<hr>
						<div id="signinErrorMessage">
						</div>
							<a v-on:click.stop="showInvestorLogin($event)">
								Sign In
							</a>
							<div id=signin>
								<v-text-field
									name="username-input"
									label="Username"
									v-model="username"
								>
								</v-text-field>
								<v-text-field
									v-model="password"
									:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
									:type="show1 ? 'text' : 'password'"
									name="password-input"
									label="Password"
									hint=""
									@click:append="show1 = !show1"
									v-on:keyup="lookForEnter($event)"
								></v-text-field>
								<v-btn
									@click="investorLogin($event)"
									depressed
									:style="{left: '50%', transform:'translateX(-50%)'}"
								>
									Signin	
									<v-icon>mdi-login</v-icon>
								</v-btn>
							</div>
						</v-col>

					</v-row>
				</v-container>
			</div>
			<div v-else>
				Bad
			</div>
			
		</div>
	</v-container>
</template>

<style>
.link {
	cursor: pointer;
}

#signinErrorMessage {
	height: 24px;
	color: red;
}

#signin {
	display: none;
}

a {
	text-decoration: none;
}

.hblock {
}

.hlink {
	font-weight: bold;
}


.hdesc {
}

.dividerL2 {
	background-color: #d0d0d0;
	border: none;
	height: 1px;
	width: 90%;
}

#menuL2Ins {
	max-width: 1800px;
	height: 100%;
	margin: 0 auto;
}

#menuRL2 {
	box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);
	border-top-style: none;
	border-top-width: thin;
	border-top-color: #1976d2;
	background-color: #f5f5f5;
	width: 100%;
	left: 50%;
	transform: translate(0px, 100px);
	position: fixed;
	z-index: 5;
	visibility: hidden;
}

#menuL2 {
	box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);
	border-top-style: none;
	border-top-width: thin;
	border-top-color: #1976d2;
	background-color: #f5f5f5;
	width: 100%;
	left: 50%;
	transform: translate(-50%, 100px);
	position: fixed;
	z-index: 5;
	visibility: hidden;
}

</style>

<script>
export default {
	name: 'TopMenu',
	data () {
		return {
			show1: false,
			inL2: false,
			inTab: false,
			closeDelay: 200, 
			activeTab: null,
			activeTabName: null,
			tabSwitched: false,
			tabSwitchedFromNull: false,
			numCols: 6,
			menuRoute:	{
				"/products":"menuL2",
				"/why":"menuL2",
				"/contact": "menuRL2",
				"/signin": "menuRL2"
			},
			menu: { 
			},
			menu2: { 
			},
			menuL2: {
				snippet: "",
				children: []
			},
			menuL2Default: {
				snippet: "",
				children: []
			},
			defaultIcon: {
				name: null,
				color: null
			},
			upIcon: {
				name: "mdi-chevron-up",
				color: "default"
			},
			downIcon: {
				name: "mdi-chevron-down",
				color: "green"
			}
		}
	},

	methods: {
		go(to) {
			window.location.href=to
		},
		persistInvestor() {
			if(this.$root.investor==null || this.$root.investor.loggedIn==false) {
				localStorage.removeItem("investorLogin")
			} else {
				localStorage.setItem("investorLogin", JSON.stringify(this.$root.investor))
			}
		},
		
		readInvestor() {
			this.$root.investor = {
				loggedIn: false,
				FirstName: ""
			}

			var inv = JSON.parse(localStorage.getItem("investorLogin"))
			if(inv!=null) {
				this.$root.investor = inv;
			}
			console.log("Investor: " + inv);
		},

		investorLogout(e) {
			this.$root.investor = {
				loggedIn: false,
				FirstName: ""
			}
			this.persistInvestor();

			console.log("updating page...");
			location.reload();
		},

		lookForEnter(e) {
			if(e.keyCode==13) {
				this.investorLogin(e);
			}
		},

		investorLogin(e) {
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			headers['USERNAME'] = this.username;
			headers['PASSWORD'] = this.password; 

			var otp = prompt("Please enter your OTP code.  This is not required for read-only access.");
	
			if(otp.length>0) {
				headers['OTP'] = otp; 
			}	

			this.$http.get("/svc/profile/", {headers}).then(response => {
				if(response&&response.data&&response.data.code!=401) {
					console.log(JSON.stringify(response.data))
					
					this.$root.investor = response.data
					this.$root.investor.roles = response.data.Role.split(" ");
					this.$root.investor.loggedIn=true;
					this.$root.investor.username = this.username;
					this.$root.investor.password = this.password;
					this.$root.investor.sessionKey = response.data.sessionKey;
					this.$root.investor.uid = response.data.uid;

					this.$root.investor.isMA = false;	
					this.$root.investor.isBlogger = false;
					this.$root.investor.isInvestor = false;
					this.$root.investor.isReporter = false;
					this.$root.investor.isAgent = false;
					this.$root.investor.isUpdater = false;

					// Set roles
					var roles = response.data.Role.split(" ")
					for(var role in roles) {
						console.log("Checking role: " + roles[role])
						if(roles[role]=="ma") {
							this.$root.investor.isMA = true;	
							this.$root.investor.isBlogger = true;
							this.$root.investor.isInvestor = true;
							this.$root.investor.isReporter = true;
							this.$root.investor.isAgent = true;
							this.$root.investor.isUpdater = true;
						} else if(roles[role]=="investor") {
							this.$root.investor.isInvestor = true;
						} else if(roles[role]=="blogger") {
							this.$root.investor.isBlogger = true;
						} else if(roles[role]=="reporter") {
							this.$root.investor.isReporter = true;
						} else if(roles[role]=="agent") {
							this.$root.investor.isAgent = true;
						} else if(roles[role]=="updater") {
							this.$root.investor.isUpdater = true;
						}
					}
					
					this.$root.updateNewMessages();
					this.persistInvestor();

					this.dismissL2();
					this.username = ""
					this.password = ""
					document.getElementById("signinErrorMessage").innerHTML="";

					console.log("updating page...");
					location.reload();
				} else {
					document.getElementById("signinErrorMessage").innerHTML="Invalid Credentials";
				}
				console.log(JSON.stringify(response.data,"\t"))
			})
		},
		showInvestorLogin(e) {
			if(document.getElementById("signin").style.display!="block") {
				document.getElementById("signin").style.display = "block"
			} else {
				document.getElementById("signin").style.display = "none"
			}
		},
		linkMO(e) {
			var i=0;
			var children = e.target.parentElement.children;
			for(i=0; i<children.length; i++) {
				if(children[i].classList.contains("hdesc")) {
					children[i].style.textDecoration="underline";
				}
			}
		},
		linkML(e) {
			console.log("mouseleave")
			var i=0;
			var children = e.target.parentElement.children;
			for(i=0; i<children.length; i++) {
				if(children[i].classList.contains("hdesc")) {
					children[i].style.textDecoration="none";
				}
			}
		},
		openTab(t) {
			var children = t.children;
			var i=0;
			for(i=0; i<children.length; i++) {
				if(children[i].classList.contains(this.downIcon.name)) {
					children[i].classList.remove(this.downIcon.name)
					children[i].classList.add(this.upIcon.name)
				}
			}
		},

		closeTab(t) {
			if(t==null) {
				return;
			}

			var children = t.children;
			var i=0;
			for(i=0; i<children.length; i++) {
				if(children[i].classList.contains(this.upIcon.name)) {
					children[i].classList.remove(this.upIcon.name)
					children[i].classList.add(this.downIcon.name)
				}
			}

			this.activeTab = null;
			this.activeTabName = null;
		},

		RL2MO(e) {
			console.log("mouseover")
		},

		RL2ML(e) {
			console.log("mouseleave")
		},

		openL2(tab_data) {

			// Swap icon
			this.menuL2 = tab_data; 
			this.numCols = 12/tab_data.children.length;

			console.log("openL2")
			console.log("\tinL2: "+this.inL2)
			console.log("\tinTab: "+this.inTab)

			// menus in menu2 don't take full width
			if(tab_data.menuID=="menuRL2") {
				var style = getComputedStyle(this.activeTab);
				var left = parseInt(style.marginLeft);
				var width = this.activeTab.clientWidth;

				var rect = this.activeTab.getBoundingClientRect();
				var win = this.activeTab.ownerDocument.defaultView;
				left = rect.left + win.pageXOffset;

				if(width<150) {
					left = left - width;	
					width = 2*width;
				}

				console.log("left=" + left + " width=" + width);
				
				document.getElementById(tab_data.menuID).style.width = width + "px";
				document.getElementById(tab_data.menuID).style.left = left + "px";
			}

			document.getElementById(tab_data.menuID).style.visibility = "visible"
		},

		dismissL2() {
			console.log("Dismiss")

			// This only gets called when we're not in tab and we're not in L2

			if(this.activeTab!=null) {
				console.log("Click")
				this.activeTab.click();
		
				this.closeTab(this.activeTab);
			}

			// Just dismiss both menus since we don't have a pointer to the trigger data
			document.getElementById("menuL2").style.visibility = "hidden"
			document.getElementById("menuRL2").style.visibility = "hidden"
			this.menuL2 = this.menuL2Default;
		},

		L2MO(e) {
			this.inL2=true;
			console.log("L2MO")
			console.log("\tinL2: "+this.inL2)
			console.log("\tinTab: "+this.inTab)
		},

		L2ML(e) {
			var n = this
			setTimeout(
				function() {
					n.inL2=false;
					console.log("L2ML")
					console.log("\tinL2: "+n.inL2)
					console.log("\tinTab: "+n.inTab)

					if(n.inTab==false&&n.inL2==false) {
						console.log("1 Dismissing L2...");
						n.dismissL2();
					} else {
						console.log("Don't close");
					}
				}, 
				this.closeDelay	
			)
		},

		menuMO(e, tab_data) {
			var old_name=null;

			var newActiveTab = event.target||event.srcElement;

			// ignore icons
			if(newActiveTab.nodeName=="I") {
				return;
			}

			if(tab_data.children.length>0 || tab_data.snippet.length>0) {
				old_name=this.activeTabName;
				this.tabSwitchedFromNull = this.activeTabName==null;
				this.tabSwitched = this.activeTabName!=tab_data.name;

				if(this.tabSwitched) {
					console.log("Click")
					newActiveTab.click();
					this.openTab(newActiveTab);
					this.closeTab(this.activeTab);
				}

				this.inTab = true;
				this.activeTab = newActiveTab;
				this.activeTabName = tab_data.name;
			} else if(tab_data.snippet!="" && tab_data.snippet!=null) {
				console.log("Snippet: " + tab_data.snippet)
			}

			console.log("menuMO")
			console.log("\tTab Switched: " + this.tabSwitched);
			console.log("\t\tOld Name: " + old_name)
			console.log("\t\tNew Name: " + this.activeTabName)
			console.log("\tTab Switched From Null: " + this.tabSwitchedFromNull);
			console.log("\tTab Name: " + this.activeTabName);
			console.log("\tinL2: "+this.inL2)
			console.log("\tinTab: "+this.inTab)
		
			if(tab_data.children.length>0||tab_data.snippet.length>0) {
				if(tab_data.menuID!=null) {
					this.openL2(tab_data);
				}
			}

		},

		menuML(e, tab_data) {
			if(tab_data.menuID!=null&&this.inL2!=true) {
	
				// Give the L2 mouseover event some time to fire before checking	
				var n = this
				setTimeout(
					function() {
						n.inTab = false;
						console.log("menuML")
						console.log("\tTab Switched: " + n.tabSwitched);
						console.log("\tTab Switched From Null: " + n.tabSwitchedFromNull);
						console.log("\tinL2: "+n.inL2)
						console.log("\tinTab: "+n.inTab)

						if(n.inTab==false&&n.inL2==false&&(n.tabSwitched==false||n.tabSwitchedFromNull==true)) {
							console.log("2 Dismissing L2...");
							n.dismissL2();
						} else {
							console.log("Don't close");
						}
						n.tabSwitched=false;
					}, 
					this.closeDelay	
				)
			}	
		}, 

		menuC(e, tab_data) {
			e.target.click();
			e.stopPropagation();
		},

		parseMenu(response, m) {
			var menu = response.data.menuList;
			var newMenu = {} 
			var rootID=0;

			// find the root ID
			for(const i in menu) {
				menu[i].children = []
				menu[i].handle = menu[i].link.replace("/","")
				newMenu[menu[i]["id"]] = menu[i];
				if(menu[i].parent==-1) {
					rootID = menu[i].id
				} 
			}

			// build tree	
			for(const id in newMenu) {
				var pid = newMenu[id].parent
				if(pid!=-1) {
					try {
						newMenu[pid].children.push(newMenu[id])
					} catch(e) {
						console.log("pid " + pid + " is not defined");
					}
				}
			}

			// set icon based on number of children. 
			// also, remove url link if we have children
			for(const id in newMenu) {
				if(newMenu[id].children.length>0) {
					newMenu[id].action = this.downIcon;
					newMenu[id]["route"] = newMenu[id].link;
					newMenu[id].link = ""
				} else if(newMenu[id].snippet.length>0) {
					newMenu[id].action = this.downIcon;
					newMenu[id]["route"] = newMenu[id].link;
					newMenu[id].link = ""
				} else {
					newMenu[id].action = this.defaultIcon;
				}

				try {
					newMenu[id]["menuID"] = this.menuRoute[newMenu[id].route]
				} catch(e) {
					newMenu[id]["menuID"] = null 
				}
			}

			// Sort Menu by sort_order
			this[m] = newMenu[rootID].children.sort(function(a,b) {
				return(a.sort_order>b.sort_order)
			});
		},
	},

	mounted() {
		this.readInvestor();

		this.$http.get("/svc/menu/2/").then(response => {
			this.parseMenu(response, "menu2");
		})

		this.$http.get("/svc/menu/1/").then(response => {
			this.parseMenu(response, "menu");
		})
	
		this.$root.updateNewMessages();
	}

}
</script>
