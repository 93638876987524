<template>
	<v-app>
		<TopMenu/>
		<v-main>
			<router-view>
			</router-view>
		</v-main>
		<v-footer dark>
			<Footer/>
		</v-footer>
	</v-app>
</template>

<script>
import TopMenu from './components/TopMenu';
import Footer from './components/Footer';
import ColorThief from 'color-thief'

export default {
	name: 'App',

	components: {
		TopMenu,
		Footer
	},
};
</script>
